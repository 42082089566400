<template>
  <aside class="top-nav-sidebar" v-bind:class="{ 'is-active': sidebarOpen }">
    <ul class="top-nav-sidebar-header-list">
      <li v-for="item in topnavComputed" v-bind:key="item.id" :class="{ 'is-active': doSetActive(item) }">
        <a v-bind:href="item.url" v-bind:target="item.link_target">{{  item.header }}</a>
      </li>
    </ul>

    <ul class="top-nav-sidebar-list">
      <li v-for="item in blocknavComputed" v-bind:key="item.id" :class="{ 'is-active': doSetActive(item) }">
        <a v-bind:href="item.url"
          v-bind:target="item.link_target"
          class="top-nav-sidebar-list__link">
          <img
            v-bind:src="item.image.filename"
            width="200"
            height="200"
            loading="lazy"
            v-bind:alt="item.image.alt" />
          <div>
            <h3 class="mt-0">{{ item.subheader }}</h3>
            <h2>{{ item.header }}</h2>
          </div>
        </a>
      </li>
    </ul>
  </aside>
</template>

<script>
import { computed } from "vue";
import { sidebarOpen } from "./v-top-nav.vue";


export default {
  props: {
    topnav: {
      type: Object,
    },
    blocknav: {
      type: Object,
    },
  },
  setup(props) {
    const blocknavComputed = computed(() => props.blocknav);
    const topnavComputed = computed(() => props.topnav);

    return {
      sidebarOpen,
      blocknavComputed,
      topnavComputed
    };
  },
  methods: {
    doSetActive(item) {
      var currentUrl = item.url;

      if (currentUrl.length !== 1 && !currentUrl.endsWith('/')) {
        currentUrl += "/";
      }

      return currentUrl === location.pathname;
    },
  },

};
</script>
