window.addEventListener('load', () => {

  document.body.addEventListener("click", function (e) {

      var obj = e.target;
      if (obj.tagName === "A" || obj.tagName === "BUTTON" || obj.tagName === "IMG")
          getAttributeObj(e);
  });

  document.body.addEventListener("submit", function (e) {
      getAttributeObj(e);
  });

  document.body.addEventListener("onchange", function (e) {
      getAttributeObj(e);
  });

  function getAttributeObj(e) {
      var obj = e.target;
      var parentObj = obj.parentElement;
      var el = null;

      if (obj.getAttribute("data-ga-event-action") !== null) {
          el = obj;
      }
      else if (parentObj !== null && parentObj.getAttribute("data-ga-event-action") !== null) {
          el = parentObj;
      }

      if (el !== null)
          doGATrack(el);
      else
          return false;
  }

  function doGATrack(el) {
      dataLayer.push({
          'event': 'data-ga-event',
          'event_action': el.getAttribute("data-ga-event-action"),
          'event_category': el.getAttribute("data-ga-event-page"),
          'event_label': el.getAttribute("data-ga-event-label")
      });
   }

});




