<template>
  <div class="top-nav">
    <ul class="top-nav-list">
      <li v-on:click="sidebarToggle($event)">
        <a href="#" class="is-menu" v-bind:class="{ 'is-active': sidebarOpen }"
          >&nbsp;</a
        >
        <div class="top-nav-list__hamburger-holder">
          <div
            class="top-nav-list__hamburger"
            v-bind:class="{ 'is-open': sidebarOpen }"
          ></div>
          <div></div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";

export let sidebarOpen = ref(false);

export function toggleSidebarAction() {
  sidebarOpen.value = !sidebarOpen.value;
}

export default {
  // props: {
  //   prop1: {
  //     type: String,
  //   },
  // },
  setup(props) {
    const sidebarToggle = (e) => {
      e.preventDefault();
      toggleSidebarAction();

      // set overflow on body
      // var body = document.body;
      // if (body) {
      //   if(body.classList.contains("no-scroll")){
      //     body.classList.remove("no-scroll");
      //   }
      //   else{
      //     body.classList.add("no-scroll");
      //   }
      // }      
    };

    onMounted(() => {
    //  console.log("mounted!");
    });

    return {
      sidebarOpen,
      sidebarToggle,
    };
  },
};
</script>


<style>
</style>